import React from "react"
import { Layout } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { Link } from "gatsby"

const Snippets = () => {
  return (
    <Layout>
      <GatsbySeo noindex={true} />
      <h2 className="mb-4 font-semibold">Hero Sections</h2>
      <ul className="mb-4">
        <li>
          <Link className="underline" to="/codesnippets/fullheightimage">
            Full Height Image
          </Link>
        </li>
        <li>
          <Link className="underline" to="/codesnippets/angledimageonright">
            Angled Image Right
          </Link>
        </li>
      </ul>

      <h2 className="mb-4 font-semibold">Tables</h2>
      <ul className="mb-4">
        <li>
          <Link className="underline" to="/codesnippets/widetables">
            Wide Tables
          </Link>
        </li>
      </ul>
    </Layout>
  )
}

export default Snippets
